import { CorrespondenceFile } from "@/types/CorrespondenceFile";
import { FileNameParts } from '@/types/FileNameParts';

/** Converts the fileContents in the passed CorrespondenceFile to a blob so it can be downloaded */
const convertCorrespondenceFileToBlob = (file: CorrespondenceFile) => {
    /* The file contents are encoded as a base64 string. 
     * Before we can create a blob, we must decode it */
    const byteCharacters = atob(file.fileContents);

    // Create an array for the byte numbers
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Convert the byte numbers into a Uint8Array
    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], { type: file.contentType });
};

/** Converts the correspondence file in the passed CorrespondenceFile to a blob so it can be downloaded */
const convertCorrespondenceFileToFile = (file: CorrespondenceFile) => {
    const fileName = getFileNameParts(file.fileDownloadName);
    if(!fileName){
        return;
    }

    return new File([convertCorrespondenceFileToBlob(file)], fileName.nameWithExtension, { type: file.contentType });
}

/** Retrieves all file parts for the passed file name */
const getFileNameParts = (fullFileName: string): FileNameParts | null => {
    /* ([^\\]+)     Matches one or more characters that are not a backslash (\\). This captures the file name.
     * \.([^\\]+)$  Matches a literal dot (.) followed by one or more characters that are not a backslash (\\) 
     *              until the end of the string. This captures the file extension. */
    const regex = /([^\\]+)\.([^\\]+)$/;
    const match = fullFileName.match(regex);
    
    if (!match) {
        return null;
    }
    
    return {
        nameWithExtension: match[0],
        name: match[1],
        extension: match[2]
    }
};

export { convertCorrespondenceFileToBlob, getFileNameParts, convertCorrespondenceFileToFile }