import { ref } from "vue";
import { defineStore } from "pinia";
import { ApplicantListFilters } from "@/types/applicants/ApplicantListFilter";
import { Mutex } from "async-mutex";
import { readCookie } from "@/utils/cookies/JsonCookieReader";
import { writeCookie } from "@/utils/cookies/JsonCookieWriter";
import { useCurrentUserStore } from "../CurrentUserStore";
import { Id } from '@/types/generic/Id';

export const useApplicantListStore = defineStore('applicantList', () => {
    const mutex = new Mutex();

    const cachedFilters = ref<ApplicantListFilters>();
    const currentUserStore = useCurrentUserStore();
    const applicantIds = ref<Id[]>([]);
    const displayMatchedApplicants = ref(false);
    const matchedProperty = ref<string>('');

    /** Constructs filter name for currently logged in user and site prefix */
    const getFilterName = async () => {
        const filterName = 'x-applicant-list-filters';
        const currentUser = await currentUserStore.getCurrentUserDetails();
        const sitePrefix = await currentUserStore.getSitePrefix();

        return [filterName, sitePrefix, currentUser.id].filter(item => !!item).join('-');
    };

    /** Gets the previous Applicant List filters */
    const getFilters = async () => {
        const filters = await mutex.runExclusive(async () => {
            // To improve performance, only read the cookie we don't already have the data
            if (!cachedFilters.value) {
                cachedFilters.value = readCookie<ApplicantListFilters>(await getFilterName());
            }

            return cachedFilters.value;
        });

        return filters;
    }
    
    /** Stores the current Applicant List filters so filters are remembered 
     * when user navigates back to the Applicant List */
    const updateFilters = async (filters :ApplicantListFilters) => {
        await mutex.runExclusive(async () => {
            /* Improve performance by caching the updated filters
             * This means the app won't need to read the cookie unnecessarily */
            cachedFilters.value = filters;

            // Update cookie so filters are remembered when user reloads the app
            writeCookie<ApplicantListFilters>(await getFilterName(), filters);
        });
    }

    /** Clears the current Task List filters cache */
    const clearCache = () => cachedFilters.value = undefined;

    const storeMatchedApplicants = (ids:Id[], propertyAddress:string)=>{
        displayMatchedApplicants.value = true;
        applicantIds.value = ids;
        matchedProperty.value = propertyAddress;
    };

    const fetchMatchedApplicants = ()=>{
        return applicantIds.value;
    };

    const displayStandardApplicantList = ()=>{
        displayMatchedApplicants.value = false;
    };

    const checkIfMatchedApplicantsToBeDisplayed = ()=>{
        return displayMatchedApplicants.value;
    };

    const fetchMatchedProperty = () =>{
        return matchedProperty.value;
    };

    return { getFilters, updateFilters, clearCache, storeMatchedApplicants, fetchMatchedApplicants, displayStandardApplicantList, checkIfMatchedApplicantsToBeDisplayed, fetchMatchedProperty }
});