import { Environment } from "@/enums/system/Environment";

/** Gets the Environment the app is currently running in 
 * @returns Environment enum value representing the enivronment the app is currently running in */
const getEnvironment = () :Environment => {
    if (window.location.host.includes('localhost')) {
        return Environment.Development;
    }

    switch (window.location.host) {
        case 'apptest.acquaintcrm.co.uk': return Environment.Staging;
        case 'apptest.propertybutton.ie': return Environment.Staging;
        case 'appdev.acquaintcrm.co.uk': return Environment.Development;
        default: return Environment.Production
    }
}

/** Checks whether the current url is a property button domain */
const isPropertyButtonUrl = () => window.location.host.includes('propertybutton.ie');

export { getEnvironment, isPropertyButtonUrl }