import { baseAlert } from "./baseAlert";

/** Displays a SweetAlert prompting the user to confirm whether they want to cancel */
const displayCancelConfirmationAlert = (textToDisplay:string) => {
    return baseAlert.fire({
        text: textToDisplay,
        icon: 'question',
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        showDenyButton: true,
        heightAuto: false,
    })
}

export { displayCancelConfirmationAlert }