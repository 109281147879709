import { ref } from "vue";
import { defineStore } from "pinia";
import { PropertyListFilters } from "@/types/properties/PropertyListFilter";
import { Mutex } from "async-mutex";
import { readCookie } from "@/utils/cookies/JsonCookieReader";
import { writeCookie } from "@/utils/cookies/JsonCookieWriter";
import { useCurrentUserStore } from "../CurrentUserStore";
import { Id } from '@/types/generic/Id';

export const usePropertyListFiltersStore = defineStore('propertyListFilters', () => {
    const mutex = new Mutex();

    const cachedFilters = ref<PropertyListFilters>();
    const currentUserStore = useCurrentUserStore();
    const propertyIds = ref<Id[]>([]);
    const displayMatchedProperties = ref(false);
    const contactName = ref('');

    /** Constructs filter name for currently logged in user and site prefix */
    const getFilterName = async () => {
        const filterName = 'x-property-list-filters';
        const currentUser = await currentUserStore.getCurrentUserDetails();
        const sitePrefix = await currentUserStore.getSitePrefix();

        return [filterName, sitePrefix, currentUser.id].filter(item => !!item).join('-');
    };

    /** Gets the previous Property List filters */
    const getFilters = async () => {
        const filters = await mutex.runExclusive(async () => {
            // To improve performance, only read the cookie we don't already have the data
            if (!cachedFilters.value) {
                cachedFilters.value = readCookie<PropertyListFilters>(await getFilterName());
            }

            return cachedFilters.value;
        });

        return filters;
    }

    /** Stores the current Property List filters so filters are remembered 
     * when user navigates back to the Property List */
    const updateFilters = async (filters :PropertyListFilters) => {
        await mutex.runExclusive(async () => {
            /* Improve performance by caching the updated filters
            /* This means the app won't need to read the cookie unnessarily */
            cachedFilters.value = filters;

            // Update cookie so filters are remembered when user reloads the app
            writeCookie<PropertyListFilters>(await getFilterName(), filters);
        });
    }

    /** Clears the current filters cache */
    const clearCache = () => cachedFilters.value = undefined;

    const storeMatchPropertyIdsAndName = (ids:Id[], name:string) => {
        propertyIds.value = ids;
        contactName.value = name;
        displayMatchedProperties.value = true;
    };

    const displayStandardPropertyList = ()=>{
        displayMatchedProperties.value = false;
    };

    const fetchMatchPropertyIds = ()=>{
        return propertyIds.value;
    };

    const fetchMatchedContact = () =>{
        return contactName.value;
    };

    const checkIfMatchedPropertiesToBeDisplayed = ()=>{
        return displayMatchedProperties.value;
    };

    return { getFilters, updateFilters, clearCache, storeMatchPropertyIdsAndName, fetchMatchPropertyIds, displayStandardPropertyList, checkIfMatchedPropertiesToBeDisplayed, fetchMatchedContact }
})