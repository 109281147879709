import { ref, inject } from "vue";
import { defineStore } from "pinia";
import { MergeField } from '@/types/MergeField';
import displayErrorMessage from '@/utils/ErrorHandling';
import { getErrorMessage } from 'bright-logic-core/src/errors';
import { AcquaintApiNextClient } from '@/services/AcquaintApiNextClient';

export const useMergeFieldsStore = defineStore('mergeFields', () => {
    const mergeFields = ref<MergeField[]>([]);
    const apiNextClient = inject('AcquaintApiNextClient') as AcquaintApiNextClient;

    const fetchMergeFields = async ()=>{
        try{
            mergeFields.value = await apiNextClient.httpGet('mergefields');
        } catch(err){
            displayErrorMessage(getErrorMessage(err));
            mergeFields.value = [];
        }
    };

    const returnMergeFields = async ()=>{
        if(mergeFields.value.length == 0){
            await fetchMergeFields();
        }

        return mergeFields.value;
    };

    return { returnMergeFields };
});
