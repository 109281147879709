import { App, Plugin } from 'vue';
import { AuthenticationCookieManager } from './AuthenticationCookieManager'
import { Mutex } from 'async-mutex';
import { JwtToken } from '../types/authentication/JwtToken';
import { getEnvironment } from '@/utils/system/EnvironmentReader';
import { Environment } from '@/enums/system/Environment';
import { LoginCredentials } from '@/types/authorization/LoginCredentials';
import { LoginResponse } from '@/types/authorization/LoginResponse';
import DeletedRecordError from './DeletedRecordError';

// ApiClient class - wraps up all http requests to our Acquaint APIs
export class AcquaintApiClient {
    private mutex = new Mutex();
    private _jwtToken: JwtToken = { token: '', expiresIn: 0, utcExpiryDate: '' };
    private _baseUrl: string
    private _authenticationCookieManager: AuthenticationCookieManager
    private readonly _apiRetryMax = 1;
    public _databaseConnectionToken: string = '';

    constructor() {
        this._authenticationCookieManager = new AuthenticationCookieManager;
        this._baseUrl = this.getBaseUrl();
    }

    /** Gets the base url to use for api calls based on the current environment */
    private getBaseUrl() {
        const environment = getEnvironment();

        switch (environment) {
            case Environment.Development: 
                return 'https://api-dev.acquaintcrm.co.uk/17.0/';
            case Environment.Staging: 
                return 'https://api-staging.acquaintcrm.co.uk/17.0/';
            default:
                return 'https://api.acquaintcrm.co.uk/17.0/'
        }
    }

    public async httpGet<T>(url: string): Promise<T> {
        return await this.getApiResponse<T>(url, "GET")
    }
    public async getFile(url: string): Promise<Blob> {
        // as the end point returns a ReadableStream Byte Array we should convert this to a Blob so it can be displayed on the screen
        return (await this.sendHttpRequest(url, "GET")).blob();
    }
    public async httpPut<T>(url: string, data: any): Promise<T> {
        return await this.getApiResponse<T>(url, "PUT", data)
    }
    public async httpPost<T>(url: string, data: any): Promise<T> {
        return await this.getApiResponse<T>(url, "POST", data)
    }
    public async httpDelete<T>(url: string, data: any = {}): Promise<T> {
        return await this.getApiResponse<T>(url, "DELETE", data)
    }
    public async httpPutVoid(url: string, data: any): Promise<void> {
        await this.sendHttpRequest(url, "PUT", data)
    }
    public async httpPostVoid(url: string, data: any): Promise<void> {
        await this.sendHttpRequest(url, "POST", data)
    }
    public async httpDeleteVoid(url: string, data: any = {}): Promise<void> {
        await this.sendHttpRequest(url, "DELETE", data)
    }

    public logout() {
        this.resetState()
    }   

    private async getAuthToken(): Promise<void> {
        const url = `${this.getBackendApiUrl()}/auth`

        // Calls the Auth endpoint to get a valid Acquaint API JWT
        const result = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: "POST",
        });

        this._jwtToken = await result.json();
    }

    /** Gets the url for the authentication api endpoint */
    private getBackendApiUrl() :string {
        const domainName = window.location.host.includes('propertybutton.ie') ? 'propertybutton.ie' : 'acquaintcrm.co.uk';
        let subDomain = getEnvironment() === Environment.Production ? 'app' : 'apptest';

        const environment = getEnvironment();

        switch (environment) {
            case Environment.Development: 
                subDomain = 'appdev';
                break;
            case Environment.Staging: 
                subDomain = 'apptest';
                break;
            default:
                subDomain = 'app';                
        }

        return `https://${subDomain}.${domainName}/api`;
    }

    private isJwtTokenRefreshRequired(): boolean {
        const fiveMinutuesInMilliseconds = 300000;
        const nowWithRefreshOffset = Date.now() - fiveMinutuesInMilliseconds;
        return !this._jwtToken.token || new Date(this._jwtToken.utcExpiryDate) <= new Date(new Date(nowWithRefreshOffset).toUTCString());
    }
    private async getApiResponse<T>(url: string, method: string, data: any = null): Promise<T> {
        // Builds an object using the passed generic type from the the response's json body
        const response = await this.sendHttpRequest(url, method, data)
        //console.warn(JSON.parse(await response.text())) 
        return await response.json() as T;
    }
    private async sendHttpRequest(url: string, method: string, data: any = null): Promise<Response> {
        // Ensure url has been passed
        if (!url) {
            throw 'Url must not be null or blank';
        }

        if (url.indexOf('/') === 0) {
            url = url.slice(1, url.length)
        }

        // Get URL depending on environment
        const apiUrl = this._baseUrl + url
        let apiResponse: Response = new Response();

        // To avoid potential timing issues invalidating jwt tokens, attempt to make the api call a few times if unsuccesful
        for (let retry = 0; retry < this._apiRetryMax + 1; retry++) {
            // Get new jwt token if required
            if (this.isJwtTokenRefreshRequired()) {
                /* To prevent our client recalling the server and getting multiple JWT tokens run ecclusively.
                   Once this process is unlocked if this is the first to call it will then call the API, if another process
                   unlocks before it will load the jwt so this will skip the auth API call */
                await this.mutex.runExclusive(async () => {
                    if (this.isJwtTokenRefreshRequired()) {
                        await this.getAuthToken();
                    }
                });
            }

            // Read the database connector form the cookie if it is not already saved to the client
            if (this._databaseConnectionToken == '') {
                const tokenCookie = this._authenticationCookieManager.getDatabaseConnectorToken();
                this._databaseConnectionToken = (tokenCookie != undefined ? tokenCookie.token : '');
            }

            if (data) {
                data = JSON.stringify(data);
            }

            // Makes a request to the Acquaint Api , authenticates with the dbConectorToken and Acquaint JWT
            apiResponse = await fetch(apiUrl, {
                method,
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + this._jwtToken.token,
                    'x-acquaint-database-connector': this._databaseConnectionToken,
                    'content-type': 'application/json'
                },
                body: data,
            });

            if (apiResponse.ok === true) {
                // Request successful, so return api response
                break;
            } else {
                // If the Api request failed then throw a new error with the body of the response (this will be our custom error message)
                await this.processFailedApiRequest(apiResponse, retry, apiUrl);
            }
        }

        return apiResponse;
    }

    private async processFailedApiRequest(apiResponse: Response, retryAttempt: number, apiUrl: string) {
        let responseText = '';

        // Only get response text for non-404 responses as the error text for 404 responses are hard coded
        if (apiResponse.status != 404) {
            responseText = await apiResponse.text();
        }

        switch (apiResponse.status) {
            case 401:
            case 403:
                // To avoid potential timing issues invalidating jwt tokens, reset the jwt token to cause the apiClient to re-get the jwt token in the next retry
                if (retryAttempt != this._apiRetryMax) {
                    this._jwtToken.token = '';
                    return;
                }

                // Invalid authentication details so log the user out
                console.log(apiResponse)
                console.error(`Unable to make request to API \n Status: ${apiResponse.status} \n url: ${apiUrl} \n DBtoken: ${this._databaseConnectionToken} \n JWT: ${this._jwtToken.token} \n Body: ${responseText}`)
                if (confirm(`Unable to make request to API \n Status: ${apiResponse.status} \n url: ${apiUrl} \n DBtoken: ${this._databaseConnectionToken} \n JWT: ${this._jwtToken.token} \n  Yes to Logout (App may not work) No to continue (Debug)`)) {
                    this.resetState();
                }

                throw new Error(`${responseText}`);                
            case 404:
                throw new DeletedRecordError(`The requested record could not be found, it may have been deleted.`);
            default:
                throw new Error(`${responseText}`);
        }
    }

    public async uploadFile(url: string, data: any): Promise<Response> {
        // Ensure url has been passed
        if (!url) {
            throw 'Url must not be null or blank';
        }

        if (url.indexOf('/') === 0) {
            url = url.slice(1, url.length)
        }

        // Get URL depending on environment
        const apiUrl = this._baseUrl + url
        let apiResponse: Response = new Response();

        // To avoid potential timing issues invalidating jwt tokens, attempt to make the api call a few times if unsuccesful
        for (let retry = 0; retry < this._apiRetryMax + 1; retry++) {
            // Get new jwt token if required
            if (this.isJwtTokenRefreshRequired()) {
                // To prevent our client recalling the server and getting multiple JWT tokens run ecclusively.
                // Once this process is unlocked if this is the first to call it will then call the API, if another process
                // unlocks before it will load the jwt so this will skip the auth API call
                await this.mutex.runExclusive(async () => {
                    if (this.isJwtTokenRefreshRequired()) {
                        await this.getAuthToken();
                    }
                });
            }
            // Read the database connector form the cookie if it is not already saved to the client
            if (this._databaseConnectionToken == '') {
                const tokenCookie = this._authenticationCookieManager.getDatabaseConnectorToken();
                this._databaseConnectionToken = (tokenCookie != undefined ? tokenCookie.token : '');
            }

            //Makes a request to the Acquaint Api , authenticates with the dbConectorToken and Acquaint JWT
            apiResponse = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + this._jwtToken.token,
                    'x-acquaint-database-connector': this._databaseConnectionToken,
                },
                body: data,
            });

            if (apiResponse.ok === true) {
                // Request successful, so return api response
                break;
            } else {
                // If the Api request failed then throw a new error with the body of the response (this will be our custom error message)
                await this.processFailedApiRequest(apiResponse, retry, apiUrl);
            }
        }

        return apiResponse;
    }

    public async putFile(url: string, data: any): Promise<Response> {
        // Ensure url has been passed
        if (!url) {
            throw 'Url must not be null or blank';
        }

        if (url.indexOf('/') === 0) {
            url = url.slice(1, url.length)
        }

        // Get URL depending on environment
        const apiUrl = this._baseUrl + url
        let apiResponse: Response = new Response();

        // To avoid potential timing issues invalidating jwt tokens, attempt to make the api call a few times if unsuccesful
        for (let retry = 0; retry < this._apiRetryMax + 1; retry++) {
            // Get new jwt token if required
            if (this.isJwtTokenRefreshRequired()) {
                // To prevent our client recalling the server and getting multiple JWT tokens run ecclusively.
                // Once this process is unlocked if this is the first to call it will then call the API, if another process
                // unlocks before it will load the jwt so this will skip the auth API call
                await this.mutex.runExclusive(async () => {
                    if (this.isJwtTokenRefreshRequired()) {
                        await this.getAuthToken();
                    }
                });
            }
            // Read the database connector form the cookie if it is not already saved to the client
            if (this._databaseConnectionToken == '') {
                const tokenCookie = this._authenticationCookieManager.getDatabaseConnectorToken();
                this._databaseConnectionToken = (tokenCookie != undefined ? tokenCookie.token : '');
            }

            //Makes a request to the Acquaint Api , authenticates with the dbConectorToken and Acquaint JWT
            apiResponse = await fetch(apiUrl, {
                method: "PUT",
                headers: {
                    'Authorization': 'Bearer ' + this._jwtToken.token,
                    'x-acquaint-database-connector': this._databaseConnectionToken,
                },
                body: data,
            });

            if (apiResponse.ok === true) {
                // Request successful, so return api response
                break;
            } else {
                // If the Api request failed then throw a new error with the body of the response (this will be our custom error message)
                await this.processFailedApiRequest(apiResponse, retry, apiUrl);
            }
        }

        return apiResponse;
    }

    private resetState() {
        this._jwtToken = { token: '', expiresIn: 0, utcExpiryDate: '' }
        this._databaseConnectionToken = '';
        this._authenticationCookieManager.resetState()
    }

    public async apiTestMethod(url: string, requestType: string, data: any = null) {
        // Ensure url has been passed
        if (!url) {
            throw 'Url must not be null or blank';
        }

        if (url.indexOf('/') === 0) {
            url = url.slice(1, url.length)
        }

        // Get new jwt token if required
        if (this.isJwtTokenRefreshRequired()) {
            await this.getAuthToken();
        }
        // Read the database connector form the cookie if it is not already saved to the client
        if (this._databaseConnectionToken == '') {
            const tokenCookie = this._authenticationCookieManager.getDatabaseConnectorToken();
            this._databaseConnectionToken = (tokenCookie != undefined ? tokenCookie.token : '');
        }

        if (data) {
            data = JSON.stringify(data);
        }
        //Makes a request to the Acquaint Api , authenticates with the dbConectorToken and Acquaint JWT
        const apiResponse = await fetch(url, {
            method: requestType,
            headers: {
                'Authorization': 'Bearer ' + this._jwtToken.token,
                'x-acquaint-database-connector': this._databaseConnectionToken,
                'content-type': 'application/json'
            },
            body: data,
        });
        // If the Api request failed then throw a new error with the body of the response (this will be our custom error message)
        if (apiResponse.ok === false) {
            if (apiResponse.status === 401 || apiResponse.status === 403) {
                // Invalid authentication details so log the user out
                this.resetState();
            }

            if (apiResponse.status === 404) {
                throw new Error(`The requested record could not be found, it may have been deleted.`)
            }

            throw new Error(`${await apiResponse.text()}`);
        }

        return apiResponse
    }

    public async validateRecaptchaToken(token :string) {
        const url = `${this.getBackendApiUrl()}/recaptcha`;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ token: token }),
        });
        const responseContents = await response.text()
        return responseContents == "true" ? true : false;
    }

    /** Refreshes the database connection token after a user changes password
     * @param newPassword New Password for the currently logged in user */
    public async RefreshDatabaseConnectionToken(newPassword :string) {
        /* To get an updated database connector token, we need to call the login endpoint again
         * For this, we'll need the cached login credentials and the new password */
        const siteDetails = this._authenticationCookieManager.getSiteDetails();
    
        if (!siteDetails) {
            console.error('Unable to read site details from cookie');
            return
        }
    
        const loginData :LoginCredentials = {
            sitePrefix: siteDetails.sitePrefix,
            appPassword: siteDetails.appPassword,
            userName: siteDetails.userName,
            userPassword: newPassword,
            pcid: siteDetails.pcid
    
        }
        const authenticationToken = await this.httpPost<LoginResponse>("/app/auth/login", loginData)
        
        // Overwrite the database connection token with the new token
        this._authenticationCookieManager.storeLogin(authenticationToken);
        this._databaseConnectionToken = authenticationToken.token;
    }
}

// Make api client available to Vue via dependency injection
export const AcquaintApiClientPlugin: Plugin = {
    install: (app: App) => {
        const api = new AcquaintApiClient();
        app.provide('AcquaintApiClient', api);
    }
}